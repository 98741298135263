import React, { FC } from "react";
import PostCardMeta from "@/components/PostCardMeta/PostCardMeta";
import { PostDataType } from "@/data/types";
import Link from "next/link";
import Image from "next/image";
import { Post } from "@/graphql/generated/schema";
import { getImage } from "@/config/getImage";
import { formatUrlForNews } from "@/utils/newsUrl";

export interface Card3SmallProps {
  className?: string;
  post: Post;
}

const Card3Small: FC<Card3SmallProps> = ({ className = "h-full", post }) => {
  const { postitle, image, slug } = post;

  return (
    <div
      className={`nc-Card3Small relative flex flex-row justify-between items-center ${className}`}
    >
      <Link title={postitle} aria-label={postitle} href={formatUrlForNews(post)} className="absolute inset-0" ></Link>
      <div className="relative space-y-2">
        <PostCardMeta meta={{ ...post }} />
        <h2 className="nc-card-title block text-sm sm:text-base font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100">
          <Link title={postitle} aria-label={postitle} href={formatUrlForNews(post)} className="line-clamp-2" >
            {postitle}
          </Link>
        </h2>
      </div>

      <Link title={postitle} aria-label={postitle}
        href={formatUrlForNews(post)}
        className={`block w-20 flex-shrink-0 relative rounded-lg overflow-hidden z-0 ms-4 group`}
      >
        <div className={`w-full h-0 aspect-w-1 aspect-h-1`}>
          <Image
            alt="featured"
            sizes="100px"
            className="object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
            src={getImage(image?.url)}
            fill
            title={postitle}
          />
        </div>
      </Link>
    </div>
  );
};

export default Card3Small;
