import React, { FC } from "react";
import { PostDataType } from "@/data/types";
import Avatar from "@/components/Avatar/Avatar";
import Link from "next/link";
import { Post } from "@/graphql/generated/schema";
import { getDate } from "@/utils/dateFormat";
import { getImage } from "@/config/getImage";

export interface CardAuthor2Props
  extends Pick<Post, "updatedAt" | "user"> {
  className?: string;
  readingTime?: PostDataType["readingTime"];
  hoverReadingTime?: boolean;
}

const CardAuthor2: FC<CardAuthor2Props> = ({
  className = "",
  user,
  readingTime,
  updatedAt,
  hoverReadingTime = true,
}) => {
  return (
    <Link title={user?.name} aria-label={user?.name}
      href={`/author/${user?.id}`}

      className={`nc-CardAuthor2 relative inline-flex items-center ${className}`}
    >
      <Avatar
        sizeClass="h-10 w-10 text-base"
        containerClassName="flex-shrink-0 me-3"
        radius="rounded-full"
        imgUrl={getImage(user?.avatar ?? '')}
        userName={user?.professionalName}
      />
      <div>
        <h2
          className={`text-sm text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium`}
        >
          {user?.professionalName}
        </h2>
        <span
          className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}
        >
          <span>{getDate(updatedAt)}</span>
          {readingTime && (
            <>
              <span
                className={`hidden lg:inline mx-1 transition-opacity ${hoverReadingTime ? "opacity-0 group-hover:opacity-100" : ""
                  }`}
              >
                ·
              </span>
              <span
                className={`hidden lg:inline transition-opacity ${hoverReadingTime ? "opacity-0 group-hover:opacity-100" : ""
                  }`}
              >
                {readingTime} min read
              </span>
            </>
          )}
        </span>
      </div>
    </Link>
  );
};

export default CardAuthor2;
