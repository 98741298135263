import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/@apollo+client-react-streaming@0.11.3_@apollo+client@3.11.8_@types+react@18.2.37_graphql-ws@5_o5nl4abnramb4kicy5osopm7ha/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.79.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/Avatar/Avatar.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/Card10/Card10.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/Card10/Card10V2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/Card10/Card10V3.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/Card11/Card11.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/Card15Podcast/Card15Podcast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/MySlider.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/PostCardLikeAndComment/PostCardLikeAndComment.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/Sections/GridSubCategorySelect.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/Sections/HeaderFilter.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/Sections/SectionAds.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/app/production/thehawk-frontend/src/components/Sections/SectionLatestPosts.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/components/taxonomy/TaxonomyAd.tsx");
;
import(/* webpackMode: "eager" */ "/root/app/production/thehawk-frontend/src/images/logo.svg");
