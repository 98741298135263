import Card3Small from "@/components/Card3Small/Card3Small";
import WidgetHeading1 from "@/components/WidgetHeading1/WidgetHeading1";
import { PostDataType } from "@/data/types";
import { Post, PostStatus,  PostsForCategorySectionViewQueryVariables, SortOrder, usePostsForCategorySectionViewSuspenseQuery } from "@/graphql/generated/schema";
import React, { FC } from "react";



export interface WidgetPostsProps {
  className?: string;
  posts?: Post[];
}

const WidgetPosts: FC<WidgetPostsProps> =  ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  posts = [],
}) => {

  return (
    <div className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title="🎯 Don't Miss"
        viewAll={{ label: "View all", href: "/news/breaking" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {posts.map((post) => (
          <Card3Small
            className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
            key={post.slug}
            post={post as Post}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetPosts;
