"use client"
import React, { FC, useEffect, useRef } from "react";
import Heading from "@/components/Heading/Heading";
import WidgetTags from "@/components/WidgetTags/WidgetTags";

import WidgetPosts from "@/components/WidgetPosts/WidgetPosts";

import Card4 from "@/components/Card4/Card4";
import Card7 from "@/components/Card7/Card7";
import Card9 from "@/components/Card9/Card9";
import Card10 from "@/components/Card10/Card10";
import Card11 from "@/components/Card11/Card11";
import Card14 from "@/components/Card14/Card14";
import { Post, PostStatus,  PostsForCategorySectionViewQueryVariables, SortOrder, Tag,  TagsQueryVariables, usePostsForCategorySectionViewLazyQuery, usePostsForCategorySectionViewSuspenseQuery, useTagsLazyQuery, useTagsSuspenseQuery } from "@/graphql/generated/schema";
import TrendingPost from "../WidgetPosts/TrendingPost";


//
export interface SectionLatestPostsProps {
  posts?: Post[];
  gridClass?: string;
  className?: string;
  heading?: string;
  postCardName?:
  | "card3"
  | "card4"
  | "card7"
  | "card9"
  | "card10"
  | "card11"
  | "card14";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> =  ({
  postCardName = "card3",
  heading = "Latest Articles 🎈",
  gridClass = "",
  className = "",
}) => {
  const PostsForCategorySectionViewvariables: PostsForCategorySectionViewQueryVariables = {
    where: {

      status: {
        equals: PostStatus.Public
      },
    },
    orderBy: {
      createdAt: SortOrder.Desc
    },
    take: 4
  }


  const [ LoadLatestPosts,{ data: postDataRes }] = usePostsForCategorySectionViewLazyQuery({
    variables: PostsForCategorySectionViewvariables
  })
  const postData = postDataRes?.posts ?? []
  const renderCard = (post: Post, index: number) => {
    switch (postCardName) {
      // case "card3":
      //   return (
      //     // <Card3 key={index} className="p-3 sm:p-5 2xl:p-6 " post={post} />
      //     // <Card3 key={index} className="py-3" post={post} />
      //   );
      case "card4":
        return <Card4 key={index} post={post as unknown as Post} />;
      case "card7":
        return <Card7 key={index} post={post as unknown as Post} ratio="aspect-w-5 aspect-h-5" />;
      case "card9":
        return <Card9 key={index} post={post as unknown as Post} />;
      case "card10":
        return <Card10 key={index} post={post as unknown as Post} />;
      case "card11":
        return <Card11 key={index} post={post as unknown as Post} />;
      case "card14":
        return <Card14 key={index} post={post as unknown as Post} />;
      default:
        return null;
    }
  };
  const tagsvariables: TagsQueryVariables = {

orderBy:{
posts:{
  _count:SortOrder.Desc
}
},
    take: 5,
  }
const latestSectionRef = useRef(null)

  const [LoadTags,{ data }] = useTagsLazyQuery({
    variables: tagsvariables
  })
  const trendingPostVariable: PostsForCategorySectionViewQueryVariables = {
    where: {
      status: {
        equals: PostStatus.Public
      },
      isTrending: {
        equals: true
      }
    },
    orderBy: {
      createdAt: SortOrder.Desc
    },
    take: 2
  }

  const [LoadtrendingPost,{data:trendingPostRes}] = usePostsForCategorySectionViewLazyQuery({
    variables:trendingPostVariable
  })
  const trendingPosts = trendingPostRes?.posts ?? []

  const breakingPostVariables: PostsForCategorySectionViewQueryVariables = {
    where: {
      status: {
        equals: PostStatus.Public
      },
      isBreaking: {
        equals: true
      }
    },
    orderBy: {
      createdAt: SortOrder.Desc
    },
    take: 2
  }

const [LoadbreakingPosts,{data:breakingPostsRes} ]= usePostsForCategorySectionViewLazyQuery({
  variables:breakingPostVariables
})
  const breakingNews = breakingPostsRes?.posts ?? []
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting && !data && !postDataRes && !trendingPostRes && !breakingPostsRes){

          Promise.all([LoadLatestPosts(), LoadTags(), LoadtrendingPost() , LoadbreakingPosts() ])
          observer.disconnect()
        }
      },{
        threshold:0.1
      }
    );
    if (latestSectionRef.current) {
      observer.observe(latestSectionRef.current)
    }
  
    return () => {
      observer.disconnect()
    }
  }, [data , postDataRes , trendingPostRes, breakingPostsRes])
  
  const tagsData = data?.tags ?? []
  return (
    <div className={`nc-SectionLatestPosts relative ${className}`} ref={latestSectionRef}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pe-14">
          <Heading>{heading}</Heading>
          <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
            {/* @ts-ignore */}
            {postData.map(renderCard)}
          </div>

        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:ps-10 xl:ps-0 xl:w-1/3 ">
          <WidgetTags tags={tagsData as Tag[]} />
          <TrendingPost posts={trendingPosts as Post[]} />
          {/* <WidgetAuthors /> */}
          <WidgetPosts posts={breakingNews as Post[]} />
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;
