'use client'
import React, { FC, useState } from "react";
import Heading from "@/components/Heading/Heading";
import Nav from "@/components/Nav/Nav";
import NavItem from "@/components/NavItem/NavItem";
import Button from "../Button/Button";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Category, useSubCategoriesQuery } from "@/graphql/generated/schema";
import Link from "next/link";
import { SubCategory } from "@/graphql/generated/schema";

export interface HeaderFilterProps {
  tabs?: string[];
  heading: Category;
  subCategories: SubCategory[]
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabs = ["Garden", "Fitness", "Design"],
  heading,
  subCategories
}) => {



  return (
    <div className="flex flex-col  relative">
      <Link title={heading.category} aria-label={heading.category} href={`/${heading.slug}`}>

        <Heading className={subCategories?.length>0? "mb-2":"mb-5 md:mb-7 text-neutral-900 dark:text-neutral-50"} isCenter>{heading.category}</Heading>
      </Link>
      {subCategories?.length > 0 ?


        <div className="flex justify-center align-middle items-center">
          <Nav
            className="sm:space-x-2 rtl:space-x-reverse"
            containerClassName="relative flex w-full mb-5 overflow-x-auto text-sm md:text-base justify-center align-middle items-center"
          >
            {subCategories?.map((item, index) => (

              <NavItem
                key={index}
              >
                <Link title={item.subCategoryName} aria-label={item.subCategoryName} href={`/${heading.slug}/${item.slug}`}>

                  {item.subCategoryName}
                </Link>
              </NavItem>
            ))}
          </Nav>

        </div> : null
      }
    </div>
  );
};

export default HeaderFilter;
