'use client'
import React, { FC } from "react";
import PostCardCommentBtn from "@/components/PostCardCommentBtn/PostCardCommentBtn";

import { Post,  } from "@/graphql/generated/schema";

export interface PostCardLikeAndCommentProps {
  className?: string;
  itemClass?: string;
  hiddenCommentOnMobile?: boolean;
  useOnSinglePage?: boolean;
  post: Post
}

const PostCardLikeAndComment: FC<PostCardLikeAndCommentProps> = ({
  className = "",
  itemClass = "px-3 h-8 text-xs",
  hiddenCommentOnMobile = true,
  useOnSinglePage = false,
  post
}) => {






  return (
    <div
      className={`nc-PostCardLikeAndComment flex items-center space-x-2 rtl:space-x-reverse ${className}`}
    >
     
      <PostCardCommentBtn commentCount={post?._count?.comments as number}
        className={`${hiddenCommentOnMobile ? "hidden sm:flex" : "flex"
          }  ${itemClass}`}
        isATagOnSingle={useOnSinglePage}
      />
    </div>
  );
};

export default PostCardLikeAndComment;
