"use client";
import React from "react";

import { Category } from "@/graphql/generated/schema";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next13-progressbar";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
export default function GridSubCategorySelect({
  subCategory,
  currentCategory,
}: {
  subCategory: { label: string; value: string }[];
  currentCategory: Category;
}) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const urlValue = searchParams.get(currentCategory.id);
  const onChange = (newVal: string) => {
    if (newVal) {
      router.push(`?${currentCategory.id}=${newVal}#${currentCategory.id}`);
    } else {
      router.push("/");
    }
  };

  return (
    <>
      <Select
        value={urlValue}
        filterOption={(input, option) =>
          option?.children
            // @ts-ignore
            ?.toLowerCase()
            .includes(input.toLowerCase())
        }
        className=" w-full"
        showSearch
        allowClear
        placeholder="Select"
        onChange={(value) => onChange(value)}
      >
        {subCategory.map((data) => (
          <Option value={data.value}>{data.label}</Option>
        ))}
      </Select>
    </>
  );
}
