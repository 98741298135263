"use client"
import React, { FC } from "react";

import dynamic from "next/dynamic";
const  Adcode  = dynamic(()=> import("../Dynamic"), {
  ssr:false
});

export interface SectionAdsProps {
  className?: string;

}

const SectionAds: FC<SectionAdsProps> = ({

}) => {
  return (
<Adcode/>
  );
};

export default SectionAds;
