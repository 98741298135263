import React, { FC } from "react";
import Avatar from "@/components/Avatar/Avatar";
import Link from "next/link";
import { Post } from "@/graphql/generated/schema";
import { getDate } from "@/utils/dateFormat";
import { getImage } from "@/config/getImage";
import { formatUrlForNews } from "@/utils/newsUrl";

export interface PostCardMetaV2Props {
  meta: Post;
  hiddenAvatar?: boolean;
  className?: string;
  titleClassName?: string;
  avatarSize?: string;
}

const PostCardMetaV2: FC<PostCardMetaV2Props> = ({
  meta,
  hiddenAvatar = false,
  className = "leading-none text-xs",
  titleClassName = "text-base",
  avatarSize = "h-9 w-9 text-base",

}) => {
  const { postitle, updatedAt, user } = meta ?? {};

  return (
    <div
      className={`nc-PostCardMetaV2 inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      <div className="relative flex items-center space-x-2 rtl:space-x-reverse">
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={getImage(user?.avatar ?? '')}
            userName={user?.professionalName}
          />
        )}
        <div>
          <h2 className={`block font-semibold ${titleClassName}`}>
            <Link title={postitle} aria-label={postitle} href={formatUrlForNews(meta)} className="line-clamp-1">
              {postitle}
            </Link>
          </h2>

          <Link title={user?.name} aria-label={user?.name} href={`/author/${user?.username}`}
            className="flex mt-1.5">
            <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
              {user?.professionalName}
            </span>
            <span className="text-neutral-800 dark:text-neutral-400 mx-[6px] font-medium">
              ·
            </span>
            <span className="text-neutral-800 dark:text-neutral-400 font-normal">
              {getDate(updatedAt)}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostCardMetaV2;
