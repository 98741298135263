import React, { FC } from "react";
import Avatar from "@/components/Avatar/Avatar";
import Link from "next/link";
import { Post } from "@/graphql/generated/schema";
import { getDate } from "@/utils/dateFormat";
import { getImage } from "@/config/getImage";

export interface PostCardMetaProps {
  className?: string;
  meta: Pick<Post, "updatedAt" | "user">;
  hiddenAvatar?: boolean;
  avatarSize?: string;
}

const PostCardMeta: FC<PostCardMetaProps> = ({
  className = "leading-none text-xs",
  meta,
  hiddenAvatar = false,
  avatarSize = "h-7 w-7 text-sm",
}) => {
  const { updatedAt, user, } = meta;

  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      {
        user?.id && <>
        
        <Link title={user?.name} aria-label={user?.name}
        href={`/author/${user?.username}`}
        className="relative flex items-center space-x-2 rtl:space-x-reverse"
      >
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={getImage(user?.avatar as string)}
            userName={user?.professionalName}
          />
        )}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {user?.professionalName}
        </span>
      </Link>
    
        <span className="text-neutral-800 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        </>
      }
   

        <span className="text-neutral-800 dark:text-neutral-400 font-normal">
          {getDate(updatedAt)}
        </span>
      
    </div>
  );
};

export default PostCardMeta;
