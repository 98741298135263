import React, { FC } from "react";
import PostCardSaveAction from "@/components/PostCardSaveAction/PostCardSaveAction";
import { PostDataType } from "@/data/types";
import CardAuthor2 from "@/components/CardAuthor2/CardAuthor2";
import CategoryBadgeList from "@/components/CategoryBadgeList/CategoryBadgeList";
import Image from "next/image";
import Link from "next/link";
import { Post, SubCategory } from "@/graphql/generated/schema";
import SubCategoryBadgeList from "../CategoryBadgeList/SubCategoryBadgeList";
import { getImage } from "@/config/getImage";
import PostCardLikeAndComment from "../PostCardLikeAndComment";
import { formatUrlForNews } from "@/utils/newsUrl";

export interface Card4Props {
  className?: string;
  post: Post;
}

const Card4: FC<Card4Props> = ({ className = "h-full", post }) => {
  const { postitle, image, slug, subcategory, updatedAt, user, category } =
    post;

  return (
    <div
      className={`nc-Card4 relative flex flex-col group bg-white dark:bg-neutral-900 rounded-3xl ${className}`}
    >

      <span className="block flex-shrink-0 relative w-full aspect-w-16 aspect-h-9 rounded-t-xl overflow-hidden">
        <Image
          fill
          className="object-cover"
          alt=""
          sizes="(max-width: 600px) 480px, 800px"
          src={getImage(image?.url)}
        />
      </span>

      <Link title={postitle} aria-label={postitle} href={formatUrlForNews(post)} className="absolute inset-0"></Link>

      <div className="p-4 flex flex-col flex-grow">
        <div className="space-y-2.5 mb-4">
          {
            subcategory && <SubCategoryBadgeList category={category} subcategories={[subcategory as SubCategory]} />

          }
          <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
            <Link title={postitle} aria-label={postitle} href={formatUrlForNews(post)} className="line-clamp-2" >
              {postitle}
            </Link>
          </h2>
        </div>
        <div className="flex items-end justify-between mt-auto">
          <CardAuthor2 updatedAt={updatedAt} user={user} />
          <PostCardSaveAction hidenReadingTime />
        </div>
        <div className="flex items-end justify-between mt-5">
          <PostCardLikeAndComment className="relative" post={post} />
          <PostCardSaveAction className="relative" />
        </div>
      </div>
    </div>
  );
};

export default Card4;
