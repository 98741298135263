
"use client"

import { usePathname } from "next/navigation";
import { useEffect, useRef } from "react";


const AdSense = ({ adSlot }:{
    adSlot:string
}) => {
const hasRun = useRef(false);
const pathname = usePathname()
useEffect(() => {
  if (typeof (window) !=='undefined' &&!hasRun.current) {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    hasRun.current = true;
  }
}, []);

  return (
    <div className={` relative bg-gray-200  dark:bg-neutral-800 max-w-6xl h-[160px] mx-auto ${pathname.includes("/news")?"": "mt-2"}`}>
      <p className=" text-center dark:text-slate-400 text-gray-500 font-serif pt-2 absolute right-0 left-0">
        Advertisement
      </p>
    {
     typeof (window) !=='undefined' ? 

       <div className=' my-2  !h-[150px]'>

     <ins
           className="adsbygoogle  !h-[160px] "
           style={{ display: 'flex', width: '100%' ,
            height:"150px",
            alignContent:'center', justifyContent:'center' , }}
           data-ad-client="ca-pub-9084918379047887"
           data-ad-slot="9002229455"

         ></ins>

     </div>
   :null
    }

    </div>
  );
};

export default AdSense;