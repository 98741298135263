"use client";

import React, { FC, useState } from "react";
import PostCardSaveAction from "@/components/PostCardSaveAction/PostCardSaveAction";

import PostFeaturedMedia from "@/components/PostFeaturedMedia/PostFeaturedMedia";
import PostCardMetaV2 from "@/components/PostCardMeta/PostCardMetaV2";
import Link from "next/link";
import { Post, SubCategory } from "@/graphql/generated/schema";
import SubCategoryBadgeList from "../CategoryBadgeList/SubCategoryBadgeList";
import PostCardLikeAndComment from "../PostCardLikeAndComment";
import { formatUrlForNews } from "@/utils/newsUrl";

export interface Card10Props {
  className?: string;
  post: Post;
}

const Card10: FC<Card10Props> = ({ className = "h-full", post }) => {

  const { slug, subcategory, category } = post ?? {};
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={`nc-Card10 relative flex flex-col ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >

      <Link title={post.postitle} aria-label={post.postitle} href={formatUrlForNews(post)} className="absolute inset-0" />
      <div className="block group rounded-3xl flex-shrink-0 relative w-full aspect-w-9 aspect-h-7 sm:aspect-h-9 overflow-hidden z-0">
        <div>
          <PostFeaturedMedia post={post} isHover={isHover} />
        </div>

        <Link title={post.postitle} aria-label={post.postitle}
          href={formatUrlForNews(post)}
          className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity"
        ></Link>
      </div>
      <div className="absolute top-3 inset-x-3 flex justify-between items-start space-x-4 rtl:space-x-reverse z-10">
        {
          subcategory ?
            <SubCategoryBadgeList category={category} subcategories={[subcategory as SubCategory]} /> : null
        }
        <PostCardSaveAction hidenReadingTime />
      </div>

      <div className="space-y-2.5 rtl:space-x-reverse mt-4">
        <PostCardMetaV2 meta={post as Post} />
      </div>
      <div className="flex items-end justify-between mt-5">
        <PostCardLikeAndComment className="relative" post={post} />
        <PostCardSaveAction className="relative" />
      </div>
    </div>
  );
};

export default Card10;
