'use client'
import React, { Fragment, ReactNode } from "react";

import PrevBtn from "@/components/NextPrev/PrevBtn";
import NextBtn from "@/components/NextPrev/NextBtn";
import Card4 from "@/components/Card4/Card4";
import Card7 from "@/components/Card7/Card7";
import Card9 from "@/components/Card9/Card9";
import Card10 from "@/components/Card10/Card10";
import Card11 from "@/components/Card11/Card11";
import Card10V2 from "@/components/Card10/Card10V2"; import { Post } from "@/graphql/generated/schema";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export interface MySliderProps<T> {
  className?: string;
  itemPerRow?: number;
  postCardName?: string;
  data: T[];
  renderItem?: (item: T, indx: number) => ReactNode;
  arrowBtnClass?: string;
}
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1
  }
};
export default function MySlider<T>({
  className = "",
  itemPerRow = 5,
  data,
  renderItem = () => <div></div>,
  postCardName,
  arrowBtnClass = "top-1/2 -translate-y-1/2",
}: MySliderProps<T>) {

  let CardComponent = Card4;
  let cutomRenderItemfunc: any

  if (postCardName) {

    switch (postCardName) {
      case "card4":
        CardComponent = Card4;
        break;
      case "card7":
        CardComponent = Card7;
        break;
      case "card9":
        CardComponent = Card9;
        break;
      case "card10":
        CardComponent = Card10;
        break;
      case "card10V2":
        CardComponent = Card10V2;
        break;
      case "card11":
        CardComponent = Card11;
        break;

      default:
        break;
    }

    cutomRenderItemfunc = (item: unknown, indx: number) => <CardComponent key={indx} post={item as Post} className=" mx-2" />

  }







  return (

      <div className={`nc-MySlider ${className}`}>

        <div className={`relative flow-root`} >
          <div className={`flow-root overflow-hidden rounded-xl`}>

            <Carousel responsive={responsive} ssr={true} customLeftArrow={<PrevBtn

              className={`w-9 h-9 xl:w-12 xl:h-12 text-lg absolute start-3 xl:start-6 z-[1] ${arrowBtnClass}`}
            />} customRightArrow={<NextBtn
              className={`w-9 h-9 xl:w-12 xl:h-12 text-lg absolute end-3 xl:end-3 z-[1] ${arrowBtnClass}`}
            />} swipeable={true}
              draggable={true}
            >
              {data.map((item, indx) => (
                // @ts-ignore
                <Fragment key={item?.id}>

                  {cutomRenderItemfunc(item, indx)}

                </Fragment>
              ))}
            </Carousel>
          </div>



        </div>

      </div>
  );
}
