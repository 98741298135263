"use client";

import React, { FC, useState } from "react";
import PostCardSaveAction from "@/components/PostCardSaveAction/PostCardSaveAction";

import PostCardLikeAndComment from "@/components/PostCardLikeAndComment";
import PostCardMeta from "@/components/PostCardMeta/PostCardMeta";
import PostFeaturedMedia from "@/components/PostFeaturedMedia/PostFeaturedMedia";
import Link from "next/link";
import { Post, SubCategory } from "@/graphql/generated/schema";
import SubCategoryBadgeList from "../CategoryBadgeList/SubCategoryBadgeList";
import { getDate } from "@/utils/dateFormat";
import { formatUrlForNews } from "@/utils/newsUrl";

export interface Card11Props {
  className?: string;
  post: Post;
  ratio?: string;
  hiddenAuthor?: boolean;
}

const Card11: FC<Card11Props> = ({
  className = "h-full",
  post,
  hiddenAuthor = false,
  ratio = "aspect-w-4 aspect-h-3",
}) => {


  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    //
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
      >
        <div>
          <PostFeaturedMedia post={post as Post} isHover={isHover} />
        </div>
      </div>
      <Link title={post?.postitle} aria-label={post?.postitle} href={formatUrlForNews(post)} className="absolute inset-0"></Link>
      {
        post?.subcategory ? <span className="absolute top-3 inset-x-3 z-10">
          <SubCategoryBadgeList category={post?.category} subcategories={[post?.subcategory as SubCategory]} />
        </span> : null
      }


      <div className="p-4 flex flex-col space-y-3">
        {!hiddenAuthor ? (
          <PostCardMeta meta={post} />
        ) : (
          <span className="text-xs text-neutral-500">{getDate(post?.updatedAt)}</span>
        )}
        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={post?.postitle}>
            {post?.postitle}
          </span>
        </h3>
        <div className="flex items-end justify-between mt-auto">
          <PostCardLikeAndComment className="relative" post={post} />
          <PostCardSaveAction className="relative" />
        </div>
      </div>
    </div>
  );
};

export default Card11;
